




























































































import { Component, Vue } from "vue-property-decorator";
import BackButton from "@/components/BackButton.vue";
import {
    mdiNumeric1Circle,
    mdiNumeric2Circle,
    mdiNumeric3Circle,
    mdiNumeric4Circle,
} from "@mdi/js";
@Component({
    components: { BackButton },
})
export default class FaqHowDoIClearE4832ErrorOnMyTV extends Vue {
    oneIcon: string = mdiNumeric1Circle;
    twoIcon: string = mdiNumeric2Circle;
    threeIcon: string = mdiNumeric3Circle;
    fourIcon: string = mdiNumeric4Circle;
}
