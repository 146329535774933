import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"6","md":"8","offset-md":"2","offset-lg":"3"}},[_c(VRow,[_c(VCol,[_c('h2',{staticClass:"text-center text-h4"},[_vm._v(" How do I clear E48-32 error on my TV? ")])])],1),_c(VCard,[_c(VRow,[_c(VCol,{staticClass:"px-10",attrs:{"cols":"12"}},[_c(VAlert,{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint.mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c(VIcon,{attrs:{"left":"","color":"info"}},[_vm._v(_vm._s(_vm.oneIcon))]),_vm._v(" Switch off your decoder and reboot it again. ")],1)])],1),_c(VCol,{staticClass:"px-10",attrs:{"cols":"12"}},[_c(VAlert,{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint.mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c(VIcon,{attrs:{"left":"","color":"info"}},[_vm._v(_vm._s(_vm.twoIcon))]),_vm._v(" Check the cables at the back to see if it is well fixed. ")],1)])],1),_c(VCol,{staticClass:"px-10",attrs:{"cols":"12"}},[_c(VAlert,{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint.mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c(VIcon,{attrs:{"left":"","color":"info"}},[_vm._v(_vm._s(_vm.threeIcon))]),_vm._v(" Check the skies to see if it is cloudy or drizzling in this case wait till the sky brightens up. ")],1)])],1),_c(VCol,{staticClass:"px-10",attrs:{"cols":"12"}},[_c(VAlert,{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint.mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":null}},[_c('p',[_c(VIcon,{attrs:{"left":"","color":"info"}},[_vm._v(_vm._s(_vm.fourIcon))]),_vm._v(" If you still have difficulty clearing the e48-32 error code on your DStv decoder, contact a DStv installer to fix it. ")],1)])],1)],1)],1),_c(VRow,{staticClass:"mt-10"},[_c('back-button',{staticClass:"mx-auto"})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }